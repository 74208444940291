import React from "react";
import "./Ad1.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Ad3 = () => {
  return (
    <>
      <div className="adContainer">
        <Header />
        <div className="adWrapper">
            <span>지하철 광고 Portfolio</span>
          <div className="adWrapper1">
            <img src={`${process.env.PUBLIC_URL}/img/a21.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a22.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a23.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a24.jpg`} alt="img" />
          </div>
          <div className="adWrapper2">
          <img src={`${process.env.PUBLIC_URL}/img/a25.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a26.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a27.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a28.jpg`} alt="img" />
          </div>
          <div className="adWrapper3">
          <img src={`${process.env.PUBLIC_URL}/img/a29.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a30.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a31.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a32.jpg`} alt="img" />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Ad3;
