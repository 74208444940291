import React from "react";
import "./Ad1.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Ad4 = () => {
  return (
    <>
      <div className="adContainer">
        <Header />
        <div className="adWrapper">
            <span>아파트광고 Portfolio</span>
          <div className="adWrapper1">
            <img src={`${process.env.PUBLIC_URL}/img/a36.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a37.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a41.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a39.jpg`} alt="img" />
          </div>
          <div className="adWrapper2">
          </div>
          <div className="adWrapper3">
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Ad4;
