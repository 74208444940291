import React, { useEffect } from 'react'
import Header from '../components/Header'
import './About.css'
import Footer from '../components/Footer'
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from 'react-router-dom';

const About = () => {

    useEffect(() => {
        AOS.init({ duration: 1500 }); // 설정에 따라 필요한 옵션을 추가할 수 있습니다.
      }, []);

      const navigate = useNavigate();

      const goToContact = () => {
        navigate('/contact')
      }

  return (
    <>
        <div className='aboutPageContainer'>
            <Header />
            <div className='aboutPageWrapper'>
                <div className='aboutPageTextWrapper'>
                <span data-aos="fade-up">요런콘텐츠는 광고주와 전국 매체</span>
                <br />
                <span data-aos="fade-up">원청사와의 광고를 </span><strong data-aos="fade-up">직거래 연결하는</strong>
                <br />
                <strong data-aos="fade-up">직접 광고 플랫폼</strong><span data-aos="fade-up">입니다.</span>
                <br />
                <span className='gray' data-aos="fade-up">(*서울, 경기, 세종, 대전, 대구, 부산, 광주 외 전국 가능)</span>
                <br />
                <strong data-aos="fade-up">차별화된 전략으로 광고주의 성공적인 마케팅을 꿈꾼다면</strong>
                <br />
                <strong data-aos="fade-up">직광플랫폼 요런콘텐츠와 함께하세요!</strong>
                <br />
                <button onClick={goToContact} data-aos="fade-up">견적문의 →</button>
                </div>
            </div>
            <div className='aboutSystemWrapper'>
                <div>
                    <span>광고비 수수료 최소 </span><strong data-aos="fade-up">15%-35%</strong>
                    <br />
                    <img data-aos="fade-up" src={`${process.env.PUBLIC_URL}/img/aboutSystem.png`} alt='img' />
                    </div>
                </div>
                <Footer />
        </div>
    </>
  )
}

export default About