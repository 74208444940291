import React from 'react'
import './Footer.css'
import { useNavigate } from 'react-router-dom'

const Footer = () => {

  const goToKakao = () => {
    window.open('https://pf.kakao.com/_Azdxks')
  }

  const goToInsta = () => {
    window.open('https://www.instagram.com/yorun.contents/')
  }

  const navigate = useNavigate();

  const goToMain = () => {
    navigate('/')
  }


  return (
    <>
      <div className='footerContainer'>
        <div className='footerWrapper'>
          <div className='footerLogo'>
            <img onClick={goToMain} src={`${process.env.PUBLIC_URL}/img/whiteLogo.png`} alt='img' />
          </div>
          <div className='footerTextWrapper'>
            <div className='footerTextFlex'>
            <span>요런콘텐츠</span>
            <img onClick={goToInsta} className='footerInsta' src={`${process.env.PUBLIC_URL}/img/insta.png`} alt='img' />
            <img onClick={goToKakao} className='footerKakao' src={`${process.env.PUBLIC_URL}/img/kakao.png`} alt='img' />
            </div>
            <span>대표자 : 김선미</span><span>전화번호 : 010-9436-5543, 1688-9564</span><span>사업자번호 : 728-87-01864</span>
            <br />
            <span>E-mail : yorun2022@gmail.com</span><span>주소 : 대구광역시 수성구 청수로 64, 111호(상동)</span>
            <br />
            <span>Copyrightⓒ요런콘텐츠 All rights reserved</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer