import React from "react";
import emailjs from "emailjs-com";
import "./Contact.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Contact = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const sendEmail = (e) => {
    e.preventDefault();

    const name = e.target.elements.name.value.trim();
    const phone = e.target.elements.phone.value.trim();
    const email = e.target.elements.email.value.trim();
    const category = e.target.elements.category.value;
    const message = e.target.elements.message.value.trim();

    if (!name || !phone || !email || !category || !message) {
      alert("입력값을 확인해주세요.");
      return;
    }

    emailjs
      .sendForm("service_8etbbjl", "template_vlfp4zr", e.target, "RkqGFFEa2IcswbJf7")
      .then(
        (result) => {
          console.log(result.text);
          submitAlert(true);
        },
        (error) => {
          console.log(error.text);
          submitAlert(false);
        }
      );
  };

  const submitAlert = (isSuccess) => {
    if (isSuccess) {
      alert("문의가 접수되었습니다. 빠른 시일 내에 회신드리겠습니다.");
      navigate('/');
    } else {
      alert("문의 접수 중 오류가 발생했습니다. 다시 시도해주세요.");
    }
  };
  return (
    <>
      <div className="contactContainer">
      {location.pathname === "/contact" && <Header />}
        <div className="contactWrapper">
          <div className="contactLeft">
            <div className="contactTitle">
              <span>CONTACT US</span>
            </div>
            <div className="contactInputWrapper">
              <form onSubmit={sendEmail}>
                <div className="contactIdWrapper">
                  <label>
                    <span>성함</span>
                    <input type="text" name="name" /> {/* from_name은 emailJS 템플릿에서 사용되는 변수 */}
                  </label>
                  <label>
                    <span>연락처</span>
                    <input type="text" name="phone" /> {/* contact_number는 emailJS 템플릿에서 사용되는 변수 */}
                  </label>
                  <label>
                    <span>이메일</span>
                    <input type="email" name="email" /> {/* user_email은 emailJS 템플릿에서 사용되는 변수 */}
                  </label>
                  </div>
                  <div className="contactRadioWrapper">
                    <label>문의분류</label>
                <label>
                  <input type="radio" name="category" />
                  버스
                </label>
                <label>
                  <input type="radio" name="category" />
                  택시
                </label>
                <label>
                  <input type="radio" name="category" />
                  대형마트
                </label>
                <label>
                  <input type="radio" name="category" />
                  옥외
                </label>
                <label>
                  <input type="radio" name="category" />
                  지하철
                </label>
                <label>
                  <input type="radio" name="category" />
                  터미널/쉘터
                </label>
                </div>
                <span className="contactText">문의내용</span>
                <textarea className="contactDescript" name="message" />
                <button type="submit" className="contactSubmit">문의하기 →</button>
              </form>
            </div>
          </div>
          <div className="contactRight">
            <div className="contactTop" />
            <div className="contactBottom" />
          </div>
        </div>
        {location.pathname === "/contact" && <Footer />}
      </div>
    </>
  );
};

export default Contact;
