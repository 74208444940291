import React from "react";
import Header from "../components/Header";
import "./Portfolio.css";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const Portfolio = () => {

    const navigate = useNavigate();

    const goToAd1 = () => {
        navigate('/portfolio/ad1')
    }
    const goToAd2 = () => {
        navigate('/portfolio/ad2')
    }
    const goToAd3 = () => {
        navigate('/portfolio/ad3')
    }
    const goToAd4 = () => {
        navigate('/portfolio/ad4')
    }
    const goToAd5 = () => {
        navigate('/portfolio/ad5')
    }
    const goToAd6 = () => {
        navigate('/portfolio/ad6')
    }
    const goToAd7 = () => {
        navigate('/portfolio/ad7')
    }

  return (
    <>
      <div className="portfolioContainer">
        <Header />
        <div className="portfolioWrapper">
          <div className="portfolioTextWrapper">
            <div>
              <span>Portfolio</span>
              <br />
              <span>전국 오프라인 광고 최저가 견적 솔루션</span>
            </div>
          </div>
          <div className="portfolioContentWrapper1">
            <div className="portfolioContent1">
              <img
                src={`${process.env.PUBLIC_URL}/img/ptContent1.jpg`}
                alt="img"
                onClick={goToAd1}
              />
              <span>전광판 광고</span>
              <br />
              <span>
                유동인구와 차량 통행이 많은 도심에 위치한 건물 및 옥상 벽면에
                설치하여 불특정 다수에게 노출 가능하고 임팩트가 강한 광고
              </span>
            </div>
            <div className="portfolioContent2">
              <img
                src={`${process.env.PUBLIC_URL}/img/ptContent2.jpg`}
                alt="img"
                onClick={goToAd2}
              />
              <span>버스 광고</span>
              <br />
              <span>
                반복적인 운행 구간을 통해 원하는 지역의 타겟마케팅 효과가
                뛰어나며 대중교통 이용객은 물론 도보, 차량을 이용하는 타겟층까지
                효과적인 광고
              </span>
            </div>
            <div className="portfolioContent3">
              <img
                src={`${process.env.PUBLIC_URL}/img/ptContent3.jpg`}
                alt="img"
                onClick={goToAd3}
              />
              <span>지하철 광고</span>
              <br />
              <span>
                교통수송 분담률 1위로 시간대비 가장 많은 대중교통 이용객에게
                노출되며 목표집단을 세분화한 타겟마케팅이 가능한 효율높은 광고
              </span>
            </div>
          </div>
          <div className="portfolioContentWrapper2">
            <div className="portfolioContent1">
              <img
                src={`${process.env.PUBLIC_URL}/img/ptContent4.jpg`}
                alt="img"
                onClick={goToAd4}
              />
              <span>아파트 광고</span>
              <br />
              <span>
                가장 보편적인 주거 밀집 구역 내에서 이루어져 30대 이상
                주부들에게 관심과 주목을 이끌어내기 좋으며 구매 확정으로
                이어지기 좋은 광고
              </span>
            </div>
            <div className="portfolioContent2">
              <img
                src={`${process.env.PUBLIC_URL}/img/ptContent5.jpg`}
                alt="img"
                onClick={goToAd5}
              />
              <span>버스쉘터 광고</span>
              <br />
              <span>
                주야간 모두 노출 가능하여 매일같이 이용하는 버스 승객 뿐 아니라
                정류장 인근 운전자, 도보객들에게 눈높이 노출이 가능한 광고
              </span>
            </div>
            <div className="portfolioContent3">
              <img
                src={`${process.env.PUBLIC_URL}/img/ptContent6.jpg`}
                alt="img"
                onClick={goToAd6}
              />
              <span>극장 광고</span>
              <div />
              <span>
                큰 스크린을 통해 광고의 몰입도가 높고 지상파 광고시간보다 소재의
                시간이 길어 광고주가 전달하고자하는 내용을 효과적으로 전달
                가능한 광고
              </span>
            </div>
          </div>
          <div className="portfolioContentWrapper3">
            <div className="portfolioContent1">
              <img
                src={`${process.env.PUBLIC_URL}/img/ptContent7.jpg`}
                alt="img"
                onClick={goToAd7}
              />
              <span>기타 옥외광고</span>
              <div />
              <span>
                수많은 여행객과 출장객의 이용률이 높은 장소로 탑승을 기다리는
                사람들에게 자연스럽게 노출되어 성수기엔 2~3배에 달하는 매출 상승
                효과를 기대할 수 있는 광고
              </span>
            </div>
            <div className="portfolioContent2">
            </div>
            <div className="portfolioContent3">
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Portfolio;
