import React from "react";
import "./Ad1.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Ad2 = () => {
  return (
    <>
      <div className="adContainer">
        <Header />
        <div className="adWrapper">
            <span>버스광고 Portfolio</span>
          <div className="adWrapper1">
            <img src={`${process.env.PUBLIC_URL}/img/a13.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a14.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a15.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a16.jpg`} alt="img" />
          </div>
          <div className="adWrapper2">
          <img src={`${process.env.PUBLIC_URL}/img/a17.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a18.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a19.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a20.jpg`} alt="img" />
          </div>
          <div className="adWrapper3">
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Ad2;
