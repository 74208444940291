import './App.css';
import { Route, Routes } from "react-router-dom";
import Main from './pages/Main';
import About from './pages/About';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import Ad1 from './pages/Ad1';
import Ad2 from './pages/Ad2';
import Ad3 from './pages/Ad3';
import Ad4 from './pages/Ad4';
import Ad5 from './pages/Ad5';
import Ad6 from './pages/Ad6';
import Ad7 from './pages/Ad7';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/ad1" element={<Ad1 />} />
        <Route path="/portfolio/ad2" element={<Ad2 />} />
        <Route path="/portfolio/ad3" element={<Ad3 />} />
        <Route path="/portfolio/ad4" element={<Ad4 />} />
        <Route path="/portfolio/ad5" element={<Ad5 />} />
        <Route path="/portfolio/ad6" element={<Ad6 />} />
        <Route path="/portfolio/ad7" element={<Ad7 />} />
      </Routes>
    </div>
  );
}

export default App;
