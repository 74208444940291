import React from "react";
import "./Ad1.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Ad7 = () => {
  return (
    <>
      <div className="adContainer">
        <Header />
        <div className="adWrapper">
            <span>기타 옥외광고 Portfolio</span>
          <div className="adWrapper1">
            <img src={`${process.env.PUBLIC_URL}/img/a55.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a56.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a57.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a58.jpg`} alt="img" />
          </div>
          <div className="adWrapper2">
          </div>
          <div className="adWrapper3">
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Ad7;
