import React, { useState, useEffect } from 'react';
import './Header.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const goToAbout = () => {
    navigate('/about')
  }
  const goToPortfolio = () => {
    navigate('/portfolio')
  }
  const goToContact = () => {
    navigate('/contact')
  }
  const goToMain = () => {
    navigate('/')
  }

  useEffect(() => {
    // 스크롤 이벤트를 감지하는 함수
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // 빈 배열은 최초 렌더링 시에만 실행되도록 함

  return (
    <>
      <div className='headerContainer'>
        <div className={`headerWrapper ${isScrolled ? 'scrolled' : ''}`}>
          <div className='logoWrapper'>
            <img onClick={goToMain} src={`${process.env.PUBLIC_URL}/img/logo.png`} alt='logo' />
          </div>
          <div className='mobileMenuWrapper' onClick={toggleMobileMenu}>
            <img className='mbMenu' src={`${process.env.PUBLIC_URL}/img/mbMenu.png`} alt='img' />
          </div>
          <div className='navWrapper'>
          <span
              onClick={goToAbout}
              className={`headerOff ${
                location.pathname.includes('/ad1') ||
                location.pathname.includes('/ad2') ||
                location.pathname.includes('/ad3') ||
                location.pathname.includes('/ad4') ||
                location.pathname.includes('/ad5') ||
                location.pathname.includes('/ad6') ||
                location.pathname.includes('/ad7') ||
                location.pathname.includes('/portfolio')
                  ? 'active'
                  : ''
              }`}
            >요런콘텐츠</span>
            <span
              onClick={goToPortfolio}
              className={`headerOff ${
                location.pathname.includes('/ad1') ||
                location.pathname.includes('/ad2') ||
                location.pathname.includes('/ad3') ||
                location.pathname.includes('/ad4') ||
                location.pathname.includes('/ad5') ||
                location.pathname.includes('/ad6') ||
                location.pathname.includes('/ad7') ||
                location.pathname.includes('/portfolio')
                  ? 'active'
                  : ''
              }`}
            >오프라인광고</span>
            <span
              onClick={goToContact}
              className={`headerOff ${
                location.pathname.includes('/ad1') ||
                location.pathname.includes('/ad2') ||
                location.pathname.includes('/ad3') ||
                location.pathname.includes('/ad4') ||
                location.pathname.includes('/ad5') ||
                location.pathname.includes('/ad6') ||
                location.pathname.includes('/ad7') ||
                location.pathname.includes('/portfolio')
                  ? 'active'
                  : ''
              }`}
            >견적문의</span>
          </div>
          <div className={`mobileNavWrapper ${isMobileMenuOpen ? 'mobileMenuOpen' : ''}`}>
          <img onClick={closeMobileMenu} src={`${process.env.PUBLIC_URL}/img/xButton.png`} alt='img' />
            <span onClick={() => { goToAbout(); closeMobileMenu(); }}>
              요런콘텐츠
            </span>
            <span onClick={() => { goToPortfolio(); closeMobileMenu(); }}>
              오프라인 광고
            </span>
            <span onClick={() => { goToContact(); closeMobileMenu(); }}>
              견적문의
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;