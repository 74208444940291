import React from "react";
import "./Ad1.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Ad5 = () => {
  return (
    <>
      <div className="adContainer">
        <Header />
        <div className="adWrapper">
            <span>버스쉘터광고 Portfolio</span>
          <div className="adWrapper1">
            <img src={`${process.env.PUBLIC_URL}/img/a42.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a43.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a44.jpg`} alt="img" />
            <img src={`${process.env.PUBLIC_URL}/img/a45.jpg`} alt="img" />
          </div>
          <div className="adWrapper2">
          <img src={`${process.env.PUBLIC_URL}/img/a46.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a47.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a48.jpg`} alt="img" />
          <img src={`${process.env.PUBLIC_URL}/img/a49.jpg`} alt="img" />
          </div>
          <div className="adWrapper3">
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Ad5;
