import React, { useEffect } from "react";
import Header from "../components/Header";
import "./Main.css";
import Contact from "./Contact";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const Main = () => {

  const navigate = useNavigate();

  const goToAd1 = () => {
      navigate('/portfolio/ad1')
  }
  const goToAd2 = () => {
      navigate('/portfolio/ad2')
  }
  const goToAd3 = () => {
      navigate('/portfolio/ad3')
  }
  const goToAd4 = () => {
      navigate('/portfolio/ad4')
  }
  const goToAd5 = () => {
      navigate('/portfolio/ad5')
  }
  const goToAd6 = () => {
      navigate('/portfolio/ad6')
  }
  const goToAd7 = () => {
      navigate('/portfolio/ad7')
  }

  const goToContact = () => {
    navigate('/contact')
  }

  const goToAbout = () => {
    navigate('/about')
  }

  useEffect(() => {
    AOS.init({ duration: 1500 }); // 설정에 따라 필요한 옵션을 추가할 수 있습니다.
  }, []);

  return (
    <>
      <div className="mainContainer">
        <Header />
        <div className="mainWrapper">
          <div className="mainImgWrapper">
            <div className="mainTextWrapper">
              <span data-aos="fade-in">
                사장님 입맛따라 가격따라
                <br />
                전국 오프라인 광고
                <br />
                최저가 견적 솔루션
              </span >
              <br />
              <span data-aos="fade-up-right">요런콘텐츠</span>
              <button onClick={goToAbout} className="mainButton">요런콘텐츠 바로가기</button>
            </div>
          </div>
          <div className="mainContentContainer">
            <div className="mainContentWrapper1">
              <div className="displayAd">
                <img
                  src={`${process.env.PUBLIC_URL}/img/content1.png`}
                  alt="logo"
                  data-aos="flip-left"
                />
                <div className="mainContentTextWrapper">
                  <span>멀리서도 눈에 띄는 </span>
                  <span> 전광판 광고</span>
                </div>
              </div>
              <div className="busAd">
                <img
                  src={`${process.env.PUBLIC_URL}/img/content2.png`}
                  alt="logo"
                  data-aos="flip-left"
                />
                <div className="mainContentTextWrapper">
                  <span>스쳐도 다시한번 </span>
                  <span> 버스 광고</span>
                </div>
              </div>
              <div className="subwayAd">
                <img
                  src={`${process.env.PUBLIC_URL}/img/content3.png`}
                  alt="logo"
                  data-aos="flip-left"
                />
                <div className="mainContentTextWrapper">
                  <span>스마트폰 멈추게하는 </span>
                  <span> 지하철 광고</span>
                </div>
              </div>
            </div>
            <div className="mainContentWrapper2">
              <div className="apartAd">
                <img
                  src={`${process.env.PUBLIC_URL}/img/content4.png`}
                  alt="logo"
                  data-aos="flip-right"
                />
                <div className="mainContentTextWrapper">
                  <span>입주민 사로잡는 </span>
                  <span> 아파트 광고</span>
                </div>
              </div>
              <div className="busStopAd">
                <img
                  src={`${process.env.PUBLIC_URL}/img/content5.png`}
                  alt="logo"
                  data-aos="flip-right"
                />
                <div className="mainContentTextWrapper">
                  <span>운전자도 돌아보는 </span>
                  <span> 정류장 광고</span>
                </div>
              </div>
              <div className="cinemaAd">
                <img
                  src={`${process.env.PUBLIC_URL}/img/content6.png`}
                  alt="logo"
                  data-aos="flip-right"
                />
                <div className="mainContentTextWrapper">
                  <span>영화보다 몰입되는 </span>
                  <span> 극장 광고</span>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutWrapper">
            <div className="aboutTextWrapper">
              <div>
                <span>오프라인 광고 막막할 땐</span>
                <br />
                <span>'요런콘텐츠'</span>
                <span> 하세요</span>
              </div>
              <div>
                <span>
                  요런콘텐츠는 최저가 견적으로
                  <br />
                  만족스러운 오프라인 광고 효율을 낼 수 있는
                  <br />
                  아웃라인을 제시해드립니다.
                </span>
                <button onClick={goToContact} className="contactButton">문의하기 →</button>
              </div>
            </div>
            <div className="aboutImgWrapper">
              <img
                src={`${process.env.PUBLIC_URL}/img/aboutImg.png`}
                alt="img"
              />
            </div>
          </div>
          <div className="projectWrapper">
            <div className="projectTitleWrapper">
              <span>PROJECTS</span>
              <br />
              <p>진행한 프로젝트</p>
            </div>
            <div className="projectContentWrapper">
              <div className="projectContentWrapper1">
                <div className="apartPj" onClick={goToAd4}>
                  <span>아파트 광고</span>
                  <p>
                    가장 보편적인 주거 밀집 구역 내에서 이루어져 30대 이상
                    주부들에게 관심과 주목을 이끌어내기 좋으며 구매 확정으로
                    이어지기 좋은 광고
                  </p>
                </div>
                <div className="busPj" onClick={goToAd2}>
                  <span>버스 광고</span>
                  <p>
                    반복적인 운행 구간을 통해 원하는 지역의 타겟마케팅 효과가
                    뛰어나며 대중교통 이용객은 물론 도보, 차량을 이용하는
                    타겟층까지 효과적인 광고
                  </p>
                </div>
                <div className="busStopPj" onClick={goToAd5}>
                  <span>버스쉘터 광고</span>
                  <p>
                    주야간 모두 노출 가능하여 매일같이 이용하는 버스 승객 뿐
                    아니라 정류장 인근 운전자, 도보객들에게 눈높이 노출이 가능한
                    광고
                  </p>
                </div>
              </div>
              <div className="projectContentWrapper2">
                <div className="displayPj" onClick={goToAd1}>
                  <span>전광판 광고</span>
                  <p>
                    유동인구와 차량 통행이 많은 도심에 위치한 건물 및 옥상
                    벽면에 설치하여 불특정 다수에게 노출 가능하고 임팩트가 강한
                    광고
                  </p>
                </div>
                <div className="subwayPj"onClick={goToAd3}>
                  <span>지하철 광고</span>
                  <p>
                    교통수송 분담률 1위로 시간대비 가장 많은 대중교통 이용객에게
                    노출되며 목표집단을 세분화한 타겟마케팅이 가능한 효율높은
                    광고
                  </p>
                </div>
                <div className="cinemaPj"onClick={goToAd6}>
                  <span>극장 광고</span>
                  <p>
                    큰 스크린을 통해 광고의 몰입도가 높고 지상파 광고시간보다
                    소재의 시간이 길어 광고주가 전달하고자하는 내용을 효과적으로
                    전달 가능한 광고
                  </p>
                </div>
                <div className="oohPj"onClick={goToAd7}>
                  <span>기타 옥외광고</span>
                  <p>
                    수많은 여행객과 출장객의 이용률이 높은 장소로 탑승을
                    기다리는 사람들에게 자연스럽게 노출되어 성수기엔 2~3배에
                    달하는 매출 상승 효과를 기대할 수 있는 광고
                  </p>
                </div>
              </div>
            </div>
            <Contact />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
